import { computed, effect, inject, Injectable, signal, untracked } from "@angular/core"
import { collection, Firestore, onSnapshot, query, Timestamp, Unsubscribe, where } from "@angular/fire/firestore"
import {
  AllContentDocs,
  Classification,
  Content,
  ContentDoc,
  ContentType,
  ContentZod,
  EditStatus,
  KeyedContent,
  PublishStatus,
  ReviewStatus,
  Story,
} from "./content.model"
import { ProfileService, Role } from "../profile/profile.service"
import { RouteService } from "../../services/route.service"
import { regionData, RegionEnum } from "../../regions/region.model"
import { FieldType, Image, SectionEnum, TextArea, TextAreaStyleType, Video } from "../shared/fields/fields.type"
import { LinkTargetType, UtilsService } from "@shared"
import { compressToUTF16, decompressFromUTF16 } from "lz-string"
import { isEqual } from "lodash-es"
import { sort } from "util/sort"

type DeprecatedData = {
  settings: {
    // region?: RegionEnum,
    // types?: ContentType[]
  },
  status: {
    // createDate?: Timestamp
    // createUID?: string
  }
}
type ContentIncoming = Content & DeprecatedData

@Injectable({
  providedIn: "root",
})
export class FirestoreReadService {
  private firestore = inject(Firestore)
  private profileService = inject(ProfileService)
  private routeService = inject(RouteService)
  private utilsService = inject(UtilsService)

  private _allTimelineDocsLoaded = signal(false)
  private allTimelineDocs = signal<ContentDoc[]>([])
  private allTimelineContent_keyed = signal<{ [id: string]: Content }>({})

  private _allContributedLoaded = signal(false)
  private allContributedUnsubscribe: Unsubscribe | undefined
  private allContributedDocs = signal<ContentDoc[]>([])
  private allContributedContent_keyed = signal<{ [id: string]: Content }>({})

  private _myContributedDocsLoaded = signal(false)
  private myContributedDocsUnsubscribe: Unsubscribe | undefined
  private myContributedDocs_stream = signal<ContentDoc[]>([])
  private myContributedContent_keyed_stream = signal<{ [id: string]: Content }>({})

  private _allLocalMapsDocsLoaded = signal(false)
  private allLocalMapsDocs = signal<ContentDoc[]>([])
  private allLocalMapsContent_keyed = signal<{ [id: string]: Content }>({})

  allContentDocsByType = computed<AllContentDocs>(() => ({
    timeline: this.allTimelineDocs(),
    localMaps: this.allLocalMapsDocs(),
    allContributed: this.allContributedDocs(),
    myContributed: this.myContributedDocs_stream(),
  }))
  private allContent_keyed = computed<KeyedContent>(() => ({
    ...this.allTimelineContent_keyed(),
    ...this.allLocalMapsContent_keyed(),
    ...this.allContributedContent_keyed(), // has all Contributed content when Moderator is true
    ...this.myContributedContent_keyed_stream(), // has userId Contributed content for logged in user
  }))

  contentDocs_map = signal<Map<string, ContentDoc>>(new Map())
  /*
    private content_map = computed(() => new Map(Array
      .from(this.contentDocs_map().values())
      .flatMap(doc => Object.entries(doc.content))
    ))
  */

  stories = computed(() => new Map(this.arrayFromContent()
    .map(content => {
      return [content.id, this.storyFromContent(content)]
    }),
  ))

  timelineDocs_map = computed(() => new Map(Array
    .from(this.contentDocs_map().entries())
    .filter(([, doc]) => doc.type === ContentType.TIMELINE),
  ))
  timelineStories = computed(() => new Map(Array
    .from(this.stories().entries())
    .filter(([, story]) => story.status.contentTypes.includes(ContentType.TIMELINE)),
  ))
  timelineStories_array = computed(() =>
    Array.from(this.timelineStories().values()),
  )

  localMapDocs_map = computed(() => new Map(Array
    .from(this.contentDocs_map().entries())
    .filter(([, doc]) => doc.type === ContentType.TIMELINE),
  ))
  localMapStories = computed(() => new Map(Array
    .from(this.stories().entries())
    .filter(([, story]) => story.status.contentTypes.includes(ContentType.TIMELINE)),
  ))
  localMapStories_array = computed(() => Array
    .from(this.localMapStories().values())
    .sort((a, b) => sort(a.settings.title, b.settings.title)),
  )

  allContentLinks_map = computed(() => {
    const content_array = this.arrayFromContent()
      .filter(content => content.status.publishStatus === PublishStatus.PUBLISHED)
    const link_map = new Map(content_array
      .map(content => [content.id, new Set<string>()]))
    content_array.forEach(content => {
      content.settings.links.forEach(link => {
        if (this.content_map().get(link)?.status.publishStatus === PublishStatus.PUBLISHED) {
          link_map.get(link)?.add(content.id)
          link_map.get(content.id)?.add(link)
        }
      })
    })
    return link_map
  })

  private _content_map_national = signal<Map<string, Content>>(new Map())
  private _content_map_regional = signal<Map<string, Content>>(new Map())
  private content_map = computed(() =>
    new Map([...this._content_map_national().entries(), ...this._content_map_regional().entries()])
  )

  private localStorageContent_array = signal<Content[]>([])
  private lastFetched = signal<Timestamp>(Timestamp.fromMillis(0))

  private _contentLoaded_national = signal(false)
  private _contentLoaded_regional = signal(false)
  contentLoaded = computed(() =>
    this._contentLoaded_national() && this._contentLoaded_regional()
  )

  /**
   * enable regionCache updates
   */
  updateRegionCache = signal(false)
  useCompression = true

  arrayFromContent = computed(() =>
    Array.from(this.content_map().values()),
  )

  arrayFromStories = computed(() =>
    Array.from(this.stories().values()),
  )

  constructor() {
    effect(() => {
      if (this.routeService.regionIsSet()) {
        untracked(() => {
          this.getLocalStorageCache()
        })
      }
    })

    effect(() => {
      const content_map = this.content_map()
      if (this.contentLoaded()) {
        untracked(() => {
          const content_array = Array.from(content_map.values())
          // const mergedMap = new Map([...map1.entries(), ...map2.entries()]);
          /**
           * update caches when needed
           */
          if (!isEqual(this.localStorageContent_array(), content_array)) {
            localStorage.setItem("lastUpdated", Date.now().toString())
            if (this.useCompression) localStorage.setItem("content", compressToUTF16(JSON.stringify(content_array)))
            if (!this.useCompression) localStorage.setItem("content", JSON.stringify(content_array))
          }
          /*
                    if (this.profileService.isEditor() && this.updateRegionCache()) {
                      this.setRegionCache(content_array)
                      this.updateRegionCache.set(false)
                    }
          */
        })
      }
    })
  }

  getContentById(id: string | undefined) {
    return id && this.content_map().get(id) || undefined
  }

  getStoryById(id: string | undefined) {
    return id && this.stories().get(id) || undefined
  }

  storyFromContent(content: Content) {
    const fields_map = new Map(Object.entries(content.rows)
      .map(([rowId, row]) => [row.section, {
        ...row,
        rowId,
      }]))
    const body = fields_map.get(SectionEnum.BODY) as TextArea || this.newBody()
    const note = fields_map.get(SectionEnum.NOTE) as TextArea || this.newNote()
    const story: Story = {
      created: content.userActions.created[0],
      id: content.id,
      settings: content.settings,
      status: content.status,
      [SectionEnum.BODY]: { ...body, value: body.value.replace(/\n/g, "<br>") },
      [SectionEnum.NOTE]: { ...note, value: note.value.replace(/\n/g, "<br>") },
      [SectionEnum.IMAGE]: fields_map.get(SectionEnum.IMAGE) as Image || this.newImage(),
      [SectionEnum.VIDEO]: fields_map.get(SectionEnum.VIDEO) as Video || this.newVideo(),
      [SectionEnum.SUBTITLE]: fields_map.get(SectionEnum.SUBTITLE) as TextArea || this.newSubTitle(),
      [SectionEnum.TITLE]: fields_map.get(SectionEnum.TITLE) as TextArea || this.newTitle(),
    }
    return story
  }

  private getLocalStorageCache() {
    const lastUpdated = Number(localStorage.getItem("lastUpdated")) // number | NaN
    const localStorageContent_string = localStorage.getItem("content")
    const localStorageContent_array = this.stringToContent_array(localStorageContent_string, this.useCompression)
    this.localStorageContent_array.set(localStorageContent_array)

    const lastFetched = lastUpdated > (30 * 1000 * 3600 * 24) // refresh monthly
      ? Timestamp.fromMillis(lastUpdated)
      : Timestamp.fromMillis(0) // refresh monthly
    this.lastFetched.set(lastFetched)

    this._content_map_national.set(new Map(
      localStorageContent_array
        .filter(content => content.status.classification === Classification.NATIONAL)
        .map(content => [content.id, content])
    ))
    this._content_map_regional.set(new Map(
      localStorageContent_array
        .filter(content => content.status.classification !== Classification.NATIONAL)
        .map(content => [content.id, content])
    ))

    this.subscribeToLatestContent_national()
    this.subscribeToLatestContent_regional()

    /*
    const localCacheAge = lastFetched && (new Date().getTime() - lastFetched.toMillis()) / (1000 * 3600 * 24)
        if (lastFetched && localCacheAge && localCacheAge < 10 && localStorageContent_array.length >= 300) {
          this.subscribeToLatestContent()
        } else {
          this.getRegionCache()
        }
    */
  }

  /*
    private getRegionCache() {
      getDoc(doc(collection(this.firestore, "content-cache"), "NATIONAL"))
        .then(documentSnapshot => {
          const contentCacheDoc = documentSnapshot.data() as ContentCacheDoc | undefined
          const regionCacheContent_array = this.stringToContent_array(contentCacheDoc?.data, true)
          const content_map = new Map(this.content_map())
          regionCacheContent_array.forEach(content => content_map.set(content.id, content))
          this._content_map.set(content_map)
          this.subscribeToLatestContent()
        })
        .catch(error => console.log(error))
    }
  */

  /*
    private setRegionCache(content: Content[]) {
      const compressedContentString = compressToUTF16(JSON.stringify(content))
      const contentCacheDoc: ContentCacheDoc = {
        data: compressedContentString,
        lastUpdated: Timestamp.now(),
        region: RegionEnum.NATIONAL
      }
      setDoc(doc(collection(this.firestore, "content-cache"), "NATIONAL"), contentCacheDoc)
    }
  */

  private subscribeToLatestContent_national() {
    const contentQuery = query(
      collection(this.firestore, "content"),
      where("status.classification", "==", Classification.NATIONAL),
      where("lastUpdated", ">", this.lastFetched()),
    )
    onSnapshot(
      contentQuery,
      (snapshot) => {
        const content_map = new Map(this._content_map_national())
        snapshot
          .docChanges()
          .forEach(change => {
            // console.log(change.type)
            // console.log(change.doc.data())
            switch (change.type) {
              case "removed": {
                const contentId = change.doc.id
                content_map.delete(contentId)
              }
                break
              case "added":
              case "modified": {
                const rawContent = change.doc.data() as Content
                const content = this.contentTransform(rawContent)
                if (content) {
                  if (content.deleted) {
                    content_map.delete(content.id)
                  }
                  if (!content.deleted) {
                    content_map.set(content.id, content)
                  }
                }
              }
            }
          })
        // console.log("national content_map:", content_map)
        this._content_map_national.set(content_map)
        this._contentLoaded_national.set(true)
      },
      (error) => {
        console.error("Error getting content:", error)
      },
    )
  }

  private subscribeToLatestContent_regional() {
    const contentQuery = query(
      collection(this.firestore, "content"),
      where("status.classification", "!=", Classification.NATIONAL),
      where("status.region", "==", this.routeService.region()),
      where("lastUpdated", ">", this.lastFetched()),
    )
    onSnapshot(
      contentQuery,
      (snapshot) => {
        const content_map = new Map(this._content_map_regional())
        snapshot
          .docChanges()
          .forEach(change => {
            // console.log(change.type)
            // console.log(change.doc.data())
            switch (change.type) {
              case "removed": {
                const contentId = change.doc.id
                content_map.delete(contentId)
              }
                break
              case "added":
              case "modified": {
                const rawContent = change.doc.data() as Content
                const content = this.contentTransform(rawContent)
                if (content) {
                  if (content.deleted) {
                    content_map.delete(content.id)
                  }
                  if (!content.deleted) {
                    content_map.set(content.id, content)
                  }
                }
              }
            }
          })
        // console.log("regional content_map:", content_map)
        this._content_map_regional.set(content_map)
        this._contentLoaded_regional.set(true)
      },
      (error) => {
        console.error("Error getting content:", error)
      },
    )
  }

  /*
    private getLegacyContent() {
      getDocs(collection(this.firestore, "content"))
        .then(querySnapshot => {
          const content_map: Map<string, Content> = new Map()
          const docs = querySnapshot.docs
          docs.forEach(document => {
            const rawContent = document.data() as Content
            const content = this.contentTransform(rawContent)
            if (content) {
              content_map.set(content.id, content)
            }
          })
          this.legacyContent_array.set(Array.from(content_map.values()))
        })
        .catch(error => console.log(error))
        .finally(() => this.legacyContent_loaded.set(true))
    }
  */

  stringToContent_array(cachedContentString: string | undefined | null, compressed: boolean) {
    if (cachedContentString) {
      let cachedContent: Content[] = []
      try {
        if (compressed) {
          cachedContent = JSON.parse(decompressFromUTF16(cachedContentString))
        }
        if (!compressed) {
          cachedContent = JSON.parse(cachedContentString)
        }
      } catch (error) {
        console.error("Invalid JSON string:", error)
      }
      if (Array.isArray(cachedContent) && cachedContent.length) {
        return cachedContent
          .map(content => this.contentTransform(content))
          .filter((content): content is Content => Boolean(content)) // remove content that failed zodTest
      }
    }
    localStorage.removeItem("content")
    localStorage.removeItem("lastUpdated")
    return []
  }

  newContent(
    user: { id: string, name: string, role: Role, region: RegionEnum },
    contentTypes: ContentType[],
    classification: Classification,
  ): Content {
    const id = {
      title: this.utilsService.createId(),
      subTitle: this.utilsService.createId(),
      body: this.utilsService.createId(),
      image: this.utilsService.createId(),
      note: this.utilsService.createId(),
    }
    return {
      lastUpdated: Timestamp.now(),
      created: {
        date: Timestamp.now(),
        userId: user.id,
        userName: user.name,
        userRole: user.role,
        userRegion: user.region,
      },
      docId: "",
      id: this.utilsService.createId(),
      columns: [
        {
          rows: [id.title, id.subTitle, id.body, id.image, id.note],
        },
      ],
      rows: {
        [id.title]: this.newTitle(),
        [id.subTitle]: this.newSubTitle(),
        [id.body]: this.newBody(),
        [id.image]: this.newImage(),
        [id.image]: this.newVideo(),
        [id.note]: this.newNote(),
      },
      settings: {
        links: [],
        location: regionData[this.routeService.region()]?.location || { lng: 0, lat: 0 },
        position: {
          layer: 5,
          left: {
            px: 1,
          },
          top: {
            px: 1,
          },
        },
        rotation: {
          deg: 0,
        },
        title: "",
        width: {
          px: 200,
        },
      },
      status: {
        classification,
        contentTypes,
        editStatus: EditStatus.NOT_EDITING,
        publishStatus: PublishStatus.UNPUBLISHED,
        region: this.routeService.region(),
        reviewFeedbacks: [],
        reviewStatus: ReviewStatus.PENDING,
      },
      userActions: {
        edited: [],
        created: [{
          date: Timestamp.now(),
          userId: user.id,
          userName: user.name,
          userRole: user.role,
          userRegion: user.region,
        }],
        published: [],
        reviewed: [],
      },
    }
  }

  private contentDocTransform(contentDoc: ContentDoc) {
    const transformedContent_map = new Map<string, Content>()
    Object.values(contentDoc.content)
      .forEach((content: ContentIncoming) => {
        const transformedContent = this.contentTransform(content)
        if (transformedContent) { // from zodTest
          transformedContent_map.set(content.id, transformedContent)
        }
      })
    return {
      ...contentDoc,
      content: Object.fromEntries(transformedContent_map),
    }
  }


  private contentTransform(content: ContentIncoming) {
    const emailRegex = /@[a-zA-Z0-9.-]+/g

    let imageFieldExists = false
    Object.entries(content.rows)
      .forEach(([rowId, row]) => {
        if (row.field === FieldType.IMAGE) {
          imageFieldExists = true
        }
      })
    if (!imageFieldExists) {
      const rowId = this.utilsService.createId()
      const newImageField: Image = {
        ...this.newImage(),
        rowId
      }
      content.columns[0].rows.push(rowId)
      content.rows[rowId] = newImageField
    }

    let videoFieldExists = false
    Object.entries(content.rows)
      .forEach(([rowId, row]) => {
        if (row.field === FieldType.VIDEO) {
          videoFieldExists = true
        }
      })
    if (!videoFieldExists) {
      const rowId = this.utilsService.createId()
      const newVideoField: Video = {
        ...this.newVideo(),
        rowId
      }
      content.columns[0].rows.push(rowId)
      content.rows[rowId] = newVideoField
    }

    const transformedContent = {
      ...content,
      // settings: {
      //   ...content.settings,
      //   position: {
      //     ...content.settings.position,
      //     top: {
      //       ...content.settings.position.top,
      //       px: content.settings.position.top.px * 1.15
      //     },
      //     left: {
      //       ...content.settings.position.left,
      //       px: content.settings.position.left.px * 1.15
      //     },
      //   }
      // },

      userActions: {
        ...content.userActions,
        /**
         * sanitize email addresses from created.userName records
         */
        created: content.userActions.created.map(userAction => ({
          ...userAction,
          userName: userAction.userName.replace(emailRegex, ""),
        })),
      },
      status: {
        ...content.status,
        /**
         * set region to current region when content is classified as National
         * this transformation is required so that National content always has the current region,
         * allowing an admin to move content between regions by making it national, then logging into different region, then making it regional
         */
        region: content.status.classification === Classification.NATIONAL
          ? this.routeService.region()
          : content.status.region,
      },
    }

    /**
     * ContentZod is only a partial of Content atm
     * once complete, we can return zodTest.data instead of transformedContent
     * zodTest.data has only the key:values defined ContentZod
     */
    const zodTest = ContentZod.safeParse(transformedContent)
    if (zodTest.success) {
      return transformedContent
      // return zodTest.data
    }
    return

    /**
     * include deprecated types for content
     * take care so that the deprecated types are not defined in the transformedContent
     */
    /*
        const contentReviewStatus = content.status.reviewStatus
        const created = {
          date: content.status.createDate || content.userActions?.created[0].date || Timestamp.now(),
          userId: content.status.createUID || content.userActions?.created[0].userId || "s5l6ffFZ1nd1JJASOa3X08j0q4g1",
          userName: content.userActions?.created[0].userName || "",
          userRole: content.status.region === RegionEnum.NATIONAL || content.settings.region === RegionEnum.NATIONAL
            ? Role.ADMIN
            : content.userActions?.created[0].userRole || Role.CONTRIBUTOR,
          userRegion: content.status.region || RegionEnum.NATIONAL
        }

        const transformedContent: Content = {
          id: content.id,
          columns: content.columns,
          lastUpdated: content.lastUpdated || Timestamp.now(),
          created,
          docId: "",
          status: {
            classification: content.status.classification || Classification.NATIONAL,
            editStatus: content.status.editStatus || EditStatus.NOT_EDITING,
            publishStatus: content.status.publishStatus,
            reviewStatus:
              contentReviewStatus === reviewStatus.NOT_APPROVED
                ? reviewStatus.FAILED
                : contentReviewStatus === reviewStatus.IN_REVIEW
                  ? reviewStatus.ACTIVE
                  : contentReviewStatus === reviewStatus.APPROVED
                    ? reviewStatus.PASSED
                    : contentReviewStatus === reviewStatus.NOT_REVIEWED
                      ? reviewStatus.PENDING
                      : content.status.reviewStatus,
            reviewFeedbacks: content.status.reviewFeedbacks || [],
            region: content.status.region || content.settings.region || RegionEnum.NATIONAL,
            contentTypes: content.status.contentTypes || content.settings.types || [],
            showInteractiveDetails: !!content.status.showInteractiveDetails
          },
          settings: { // moving region, and types to status
            links: content.settings.links,
            location: {
              ...content.settings.location,
              lat: content.settings.location.lat || 0,
              lng: content.settings.location.lng || 0
            },
            position: content.settings.position,
            rotation: {
              deg: content.settings.rotation.deg
            },
            title: content.settings.title || content.userActions.created[0].date.seconds.toString(),
            width: {
              px: content.settings.width.px
            }
          },
          userActions: {
            edited: [
              ...content.userActions?.edited || [],
            ],
            created: [created],
            published: [
              ...content.userActions?.published || []
            ],
            reviewed: [
              ...content.userActions?.reviewed || []
            ]
          },
          rows: {
            ...Object.fromEntries(Object.entries(content.rows)
              .map(([rowId, row]) => {
                if (row.field === FieldType.TEXT_AREA) {
                  (row as TextArea).value = (row as TextArea).value
                    .replace(/<[^>]*>/g, "") // strip html
                }
                if (row.field === FieldType.IMAGE) {
                  (row as Image).section = SectionEnum.IMAGE
                }


                return [rowId, {
                  ...row,
                  rowId
                }]
              })
            )
          }
        }

        const rows_map = new Map<string, Field>(Object.entries(transformedContent.rows))
        const rowIds_set = new Set<string>()
        const found = {
          title: false,
          subTitle: false,
          body: false,
          image: false,
          note: false,
        }

        Object.entries(transformedContent.rows)
          .forEach(([rowId, row]) => {
            if (row.field === FieldType.TEXT_AREA) {
              const textField = row as TextArea
              switch (textField.section) {
                case SectionEnum.TITLE:
                  if (found.title) {
                    rowIds_set.add(rowId)
                    rows_map.set(rowId, {
                      ...row as TextArea,
                      section: SectionEnum.SUBTITLE
                    })
                  }
                  if (!found.title) {
                    found.title = true
                    rowIds_set.add(rowId)
                  }
                  break
                case SectionEnum.SUBTITLE:
                  if (!found.subTitle) {
                    found.subTitle = true
                    rowIds_set.add(rowId)
                  }
                  break
                case SectionEnum.BODY:
                  if (!found.body) {
                    found.body = true
                    rowIds_set.add(rowId)
                  }
                  break
                case SectionEnum.NOTE:
                  if (!found.note) {
                    found.note = true
                    rowIds_set.add(rowId)
                  }
                  break
              }
            }
            if (row.field === FieldType.IMAGE) {
              if (!found.image) {
                found.image = true
                rowIds_set.add(rowId)
              }
            }
          })

        if (!found.title) {
          const newId = this.utilsService.createId()
          rows_map.set(newId, this.newTitle())
          rowIds_set.add(newId)
        }
        if (!found.subTitle) {
          const newId = this.utilsService.createId()
          rows_map.set(newId, this.newSubTitle())
          rowIds_set.add(newId)
        }
        if (!found.body) {
          const newId = this.utilsService.createId()
          rows_map.set(newId, this.newBody())
          rowIds_set.add(newId)
        }
        if (!found.image) {
          const newId = this.utilsService.createId()
          rows_map.set(newId, this.newImage())
          rowIds_set.add(newId)
        }
        if (!found.note) {
          const newId = this.utilsService.createId()
          rows_map.set(newId, this.newNote())
          rowIds_set.add(newId)
        }

        transformedContent.rows = Object.fromEntries(Array
          .from(rowIds_set)
          .map(rowId => [rowId, rows_map.get(rowId)])
          .filter((item): item is [string, Field] => Boolean(item[1])))
        transformedContent.columns[0].rows = Array.from(rowIds_set)
    */

    /**
     * ContentZod is only a partial of Content atm
     * once complete, we can replace transformedContent with zodTest.data
     * which has only the key:values defined ContentZod
     */
    // const zodTest = ContentZod.safeParse(transformedContent)
    // if (zodTest.success) {
    //   return transformedContent
    // }
    // return
  }

  private newTitle(): TextArea {
    return {
      rowId: "",
      field: FieldType.TEXT_AREA,
      section: SectionEnum.TITLE,
      style: TextAreaStyleType.STYLE_5,
      value: "",
    }
  }

  private newSubTitle(): TextArea {
    return {
      rowId: "",
      field: FieldType.TEXT_AREA,
      section: SectionEnum.SUBTITLE,
      style: TextAreaStyleType.NORMAL,
      value: "",
    }
  }

  private newBody(): TextArea {
    return {
      rowId: "",
      field: FieldType.TEXT_AREA,
      section: SectionEnum.BODY,
      style: TextAreaStyleType.NORMAL,
      value: "",
    }
  }

  private newImage(): Image {
    return {
      rowId: "",
      alt: "",
      field: FieldType.IMAGE,
      filePath: "",
      fileType: "",
      section: SectionEnum.IMAGE,
      svgGraphics: null,
      targetType: LinkTargetType.SELF,
      targetPath: "",
    }
  }

  private newVideo(): Video {
    return {
      rowId: "",
      alt: "",
      field: FieldType.VIDEO,
      filePath: "",
      fileType: "",
      section: SectionEnum.VIDEO,
      svgGraphics: null,
      targetType: LinkTargetType.SELF,
      targetPath: "",
    }
  }

  private newNote(): TextArea {
    return {
      rowId: "",
      field: FieldType.TEXT_AREA,
      section: SectionEnum.NOTE,
      style: TextAreaStyleType.NORMAL,
      value: "",
    }
  }

}
