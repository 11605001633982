import { Component, input, signal } from "@angular/core"
import { TextStyles } from "./fields.type"

@Component({
  imports: [
  ],
  standalone: true,
  template: `
    <div
      [class]="textStyles().classes"
      [style.display]="textStyles().display"
      [style.font-family]="textStyles().fontFamily"
      [style.font-size.px]="textStyles().fontSize_px"
      [style.font-style]="textStyles().fontStyle"
      [style.font-weight]="textStyles().fontWeight"
      [style.letter-spacing.px]=""
      [style.line-height.%]="textStyles().lineHeight_pct"
      [style.line-height]=""
      [style.text-transform]="textStyles().textTransform"
      [style.margin-bottom.px]="0"
      [style.margin-left.px]="0"
      [style.margin-right.px]="0"
      [style.margin-top.px]="0"
      [style.padding-bottom.rem]="padding()"
      [style.padding-left.rem]="padding()"
      [style.padding-right.rem]="padding()"
      [style.padding-top.rem]="padding()"
      [innerHtml]="value()"
    ></div>
  `,
  selector: "e2e-text",
})
export class TextComponent {
  value = input.required<string>()
  textStyles = input<TextStyles>({})

  padding = signal(.2)

}
